import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setApiURLS } from './api-urls/urls';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function detectBrowserName(){
	const agent = window.navigator.userAgent.toLowerCase();
	switch (true) {
	case agent.indexOf('edge') > -1:
		return 'edge';
	case agent.indexOf('opr') > -1 && !!(window as any).opr:
		return 'opera';
	case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
		return 'chrome';
	case agent.indexOf('trident') > -1:
		return 'ie';
	case agent.indexOf('firefox') > -1:
		return 'firefox';
	case agent.indexOf('safari') > -1:
		return 'safari';
	default:
		return 'other';
	}
}

function detectBrowserVersion(){
	const userAgent = navigator.userAgent;
	let tem;
	let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

	if(/trident/i.test(matchTest[1])){
		tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return 'IE '+(tem[1] || '');
	}

	if(matchTest[1]=== 'Chrome'){
		tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
		if(tem!= null) {
			return tem.slice(1).join(' ').replace('OPR', 'Opera');
		}
	}

	matchTest = matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];

	if((tem= userAgent.match(/version\/(\d+)/i))!= null){
		matchTest.splice(1, 1, tem[1]);
	}
	return matchTest.join(' ');
}

function setBrowserVersionAttribute(){

	const htmlElement = document.querySelector('html');

	htmlElement.setAttribute(
		'browser',
		detectBrowserName()
	);

	  htmlElement.setAttribute(
		'browserversion',
		detectBrowserVersion()
	);

}

setBrowserVersionAttribute();

if(environment.production){

	enableProdMode();

	if(window){
		window.console.log = () => {};
	}

}

function setAppVersion(){

	document.querySelector('.app-version').innerHTML = `v${environment.version}`;

	if(!environment.envMainBootstrap(appBootstrap)){
		appBootstrap();
	}
}

setAppVersion();

function appBootstrap(){
	setApiURLS();
	platformBrowserDynamic().bootstrapModule(AppModule)
		.then(() => {
			document.body.removeChild(document.querySelector('#app-loading'));
			document.querySelector('body').classList.remove('app-loading');
		})
		.catch(err => console.error(err));
}
